import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="help-link">
                    <a href="https://www.aa.com/i18n/customer-service/support/privacy-policy.jsp" target="_blank">Privacy Policy</a>
                </div>
                <div className="company-name">
                    © American Airlines 2024
                </div>

            </div>
        </footer>
    );
};

export default Footer;
